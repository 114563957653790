import React from "react";
import { Link } from "gatsby";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Accordion from "~components/accordion/accordion";
import Products from "~components/products/products";
import "~styles/pages/services/_website.scss";
import Image from "../../components/base/image";
import TypeAnimation from "react-type-animation";

const SEOPage = () => (
  <Layout type="template-website">
    <SEO
      title="SEO & Website Content"
      description="Search Engine Optimization (SEO) services to optimize your on page and off page SEO for your commerce, professional service and business website through technical SEO audits, content improvements and blog writing."
    />
    <section className="page-heading">
      <div className="container">
        <TypeAnimation
          cursor={false}
          sequence={["SEO & Website Content Services", 500]}
          className={"title"}
          wrapper="h1"
        />
        <div className="website-header">
          <p>
            It doesn’t matter how fantastic you are if you can’t be found – and
            the only way to get found is high-quality technical and content SEO.
            First, we figure out where you’re winning, where you’re losing, and
            where you really need to be winning. Then we make a plan on how to
            get you there with simple monthly packages so you can take ramp up
            your SEOptimization at the pace that best fits your goals and
            budget.
          </p>
        </div>
      </div>
    </section>
    <section className="">
      <div className="container">
        <Products>
          <div
            title="SEO Audit"
            price="$450 CAD"
            subtitle="$450 CAD"
            extrainfo=""
            id="seo-audit"
          >
            <p>
              Unsure where to start or what SEO really includes? This audit is
              for you. We conduct an in-depth audit of your technical, on-page,
              and content SEO wins and opportunities, as well as a rank you next
              to your competitors, & see how your backlinking game stacks up,
              plus So. Much. More. Our report includes recommendations on
              everything from improvements to keywords, as well as a workback
              plan, should you choose The Simple Department for your
              SEOptimization.
            </p>
          </div>
          <div
            title="Small SEO Package"
            price="$875 CAD per month"
            subtitle="$875 CAD per month"
            extrainfo="+ one-time $250 CAD launch fee"
            id="small-seo-package"
          >
            <p>
              Know you need some SEO help but want to slowly chip away at key
              improvements? This monthly package is for you, with a little big
              of everything, focused around improving your existing content and
              technical SEO.
            </p>
          </div>
          <div
            title="Medium SEO Package"
            price="$1,600 CAD per month"
            subtitle="$1,600 CAD per month"
            extrainfo="+ one-time $250 CAD launch fee"
            id="medium-seo-package"
          >
            <p>
              Want to ramp up your SEO in a more focused way? This package is
              where it’s at. This package includes everything from the small SEO
              package, but with more hours in key impact areas PLUS a monthly
              SEOptimized blog full of brand-new, high-ranking content!
            </p>
          </div>
          <div
            title="Large SEO Package"
            price="$2,250 CAD per month"
            subtitle="$2,250 CAD per month"
            extrainfo="+ one-time $250 CAD launch fee"
            id="large-seo-package"
          >
            <p>
              Ready to climb the SEO ranks in a big way? Our Large SEO package
              is designed for companies that have identified SEO as a priority
              and are wanting to see big changes, fast. This package includes
              everything from the Medium SEO package, except with more hours,
              AND a second SEOptimized blog of brand new content.
            </p>
          </div>
        </Products>
      </div>
    </section>
    <section>
      <div className="container">
        <div className="website-addons p-mb">
          <h2>SEO Add-Ons</h2>
          <p>
            Some months you may want to add a little extra to your SEO servivces
            (think a new product or service launch or entering a new market).
            That’s where our add-ons come in. With everything from additional
            blogs to focused blog series to hourly support from our talented SEO
            team.
          </p>
          <p>
            <strong>
              <i>
                Please note that all of these add-ons are only available for
                purchase with the purchase of a monthly package. They cannot be
                purchased individually. 
              </i>
            </strong>
          </p>
        </div>
        <div className="blue-outline website-addonlist-wrapper">
          <Accordion>
            <div label="SEOptimized Blog" subtitle="$625 per blog">
              <p>
                Includes the planning, research, copywriting, graphics, SEO,
                publishing & creation of 1 social post to share the blog.
                <br></br>
                <Link
                  className="btn btn-dark"
                  to="/services/addons/seo-optimized-blog"
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
            <div
              label="SEOptimized Blog Series | 3 Blogs"
              subtitle="$1,500 per series"
            >
              <p>
                Includes the planning, research, copywriting, graphics, SEO,
                publishing & creation of 1 social post to share each blog (3
                blogs total).<br></br>
                <Link
                  className="btn btn-dark"
                  to="/services/addons/seo-optimized-blog-series"
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
            <div
              label="Additional SEO Content Support Hours"
              subtitle="$85 per hour"
            >
              <p>
                <Link
                  to="/services/addons/seo-content-support-hours"
                  className="btn btn-dark"
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
            <div
              label="Additional SEO Technical Support Hours "
              subtitle="$175 per hour"
            >
              <p>
                <Link
                  to="/services/addons/seo-technical-support-hours"
                  className="btn btn-dark"
                >
                  LEARN MORE
                </Link>
              </p>
            </div>
          </Accordion>
        </div>
      </div>
    </section>
    <section id="faq" className="faq-wrapper">
      <div className="container">
        <h2>FAQ</h2>
        <Accordion>
          <div label="What is the difference between technical, on-page, and content SEO?">
            <p>
              Technical SEO is related to how fast your site loads, how well the
              sitemap and index is built, and how well your site is connected
              and referenced by other websites. On-page SEO refers to everything
              that is going on on a specific page that does not include written
              content. This means alt image tags, proper use of heading tags,
              metadescriptions... the list is almost endless. Content SEO is
              related to the actual written content and the keywords that are
              being used there. Search Engine ranking is decided by a mix of all
              three of these elements, and how they compare to your competitors
              (whether you’re aware of them or not).
            </p>
          </div>
          <div label="What is the #1 thing I can do to show up on Google?">
            <p>
              Streak across an internationally televised sporting event. Barring
              that however, there is no silver bullet with SEO. The exact
              algorithm used by Search Engines is constantly shifting and huge
              technology shifts (hello ChatGP) will have a dramatic impact on
              how your content ranks in the future. While there is no single
              thing you can do to rank higher, constant improvements, meaningful
              benchmarking, and creating new, keyword-based content – that is
              also a great read – is a great place to start. Luckily for you, we
              do all that here at The Simple Department.
            </p>
          </div>
        </Accordion>
      </div>
    </section>
  </Layout>
);

export default SEOPage;
